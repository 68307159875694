<template>

  <div class="row">
    <div class="col-sm-12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Remixicon Icon</h4>
        </template>
        <template v-slot:body>
          <p>Use Class <code>&lt;i class="ri-4k-fill"&gt;&lt;/i&gt;</code></p>
          <div class="row mt-3">
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-4k-fill"></i>4k Fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-4k-line"></i>4k line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-account-box-fill"></i>Account Fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-account-pin-box-line"></i>account-pin-box</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-award-line"></i>award-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-award-fill"></i>award-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-bookmark-line"></i>bookmark-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-bookmark-fill"></i>bookmark-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-calculator-line"></i>calculator-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-calculator-fill"></i>calculator-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-calendar-line"></i>calendar-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-calendar-fill"></i>calendar-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-reply-line"></i>reply-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-reply-fill"></i>reply-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-send-plane-line"></i>send-plane-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-send-plane-fill"></i>send-plane-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-computer-line"></i>computer-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-computer-fill"></i>computer-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-cellphone-line"></i>cellphone-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-cellphone-fill"></i>cellphone-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-phone-line"></i>phone-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-phone-fill"></i>phone-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-tablet-line"></i>tablet-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-tablet-fill"></i>tablet-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-device-line"></i>device-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-device-fill"></i>device-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-battery-line"></i>battery-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-battery-fill"></i>battery-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-battery-low-line"></i>battery-low-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-battery-low-fill"></i>battery-low-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-file-line"></i>file-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-file-fill"></i>file-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-book-open-line"></i>book-open-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-book-open-fill"></i>book-open-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-lightbulb-line"></i>lightbulb-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-lightbulb-fill"></i>lightbulb-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-map-pin-line"></i>map-pin-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-map-pin-fill"></i>map-pin-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-drop-line"></i>drop-line</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-drop-fill"></i>drop-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-drop-fill"></i>drop-fill</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="ri-drop-fill"></i>drop-fill</a>
            </div>
            <div class="col-sm-12 text-center mt-3">
              <a href="https://remixicon.com/" target="blank" class="btn btn-primary">View All Icon</a>
            </div>
          </div>
        </template>
      </iq-card>
    </div>
  </div>
</template>
<script>
// import { socialvue } from '../../config/pluginInit'
export default {
  name: 'IconRemixicon',
  mounted () {
    // socialvue.index()
  }
}
</script>
